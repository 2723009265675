import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

      <main>{children}</main>
      <footer className="bg-red-700 relative z-10 text-white font-sans p-5 text-center">
        © {new Date().getFullYear()}, Сайт разработан
        {` `}
        <a
          href="https://staminaweb.ru"
          target="_blank"
          rel="noopener noreferrer"
          alt="StaminaWeb"
        >
          StaminaWeb
        </a>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
