import * as React from "react"
import PropTypes from "prop-types"
// import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header className="bg-red-700">
    <div className="container mx-auto bg-red-700 h-5">
      {/* <Link to="/" className="font-sans text-gray-200">
        {siteTitle}
      </Link> */}
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
